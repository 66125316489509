import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DeleteOutlined } from '@material-ui/icons';

import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import { AppContext } from '../../../Contexts/AppContext';
import { Send } from '../../../Misc/backend';

import './GigTypesEditPage.scss';

const GigTypesEditPage = () => {
    const [t] = useTranslation();
    const { appState, appDispatch } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [newSkill, setNewSkill] = useState('');

    const handleSaveNewSkill = async () => {
        const response = await addNewSkill(newSkill);
        appDispatch({
            type: 'setSkills',
            payload: [...appState.skills, response],
        });
        setNewSkill('');
    };

    const handleDeleteSkill = async (id) => {
        await deleteSkill(id);
        appDispatch({
            type: 'setSkills',
            payload: [...appState.skills.filter((item) => item.id !== id)],
        });
    };

    return (
        <OverlayLoader loading={loading}>
            <Helmet>
                <title>GPG App - Edit GIG Types</title>
            </Helmet>
            <div className="page">
                <Box mb={2} className="page-header">
                    {t('edit_gig_types')}
                </Box>
                <div className="type-list">
                    <div className="card type-wrapper">
                        {/*<div className="card">*/}

                        {/*</div>*/}
                        <Box mb={2} className="fs-24 bold primary_black">
                            {t('type_of_professional')}
                        </Box>
                        <Box mb={1} className="flex-align-horizontal-middle">
                            <Box>
                                <TextField
                                    id="skill"
                                    name="skill"
                                    className=""
                                    variant="outlined"
                                    color="primary"
                                    placeholder={t('add_new_gig_type')}
                                    value={newSkill}
                                    onChange={(event) => {
                                        setNewSkill(event.target.value);
                                    }}
                                />
                            </Box>
                            <Box ml={1}>
                                <Button color="primary" variant="contained" size="large" className="" onClick={handleSaveNewSkill}>
                                    {t('add')}
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                            {appState?.skills?.map(({ id, name }) => {
                                return (
                                    <div className="skill-row additional_grey_2">
                                        <div className="flex-align-horizontal-middle fs-16">
                                            <div>{name}</div>
                                        </div>

                                        <div>
                                            <Button
                                                id={id}
                                                variant="text"
                                                color="secondary"
                                                size="small"
                                                title="Delete"
                                                onClick={() => handleDeleteSkill(id)}
                                            >
                                                <DeleteOutlined color="error" />
                                            </Button>
                                        </div>
                                    </div>
                                );
                            })}
                        </Box>
                    </div>
                </div>
            </div>
        </OverlayLoader>
    );
};

const deleteSkill = async (id) => {
    let method = 'delete';
    let url = `/skills/${id}`;

    return await Send({
        method,
        url,
    });
};

const addNewSkill = async (name) => {
    let method = 'post';
    let url = '/skills';

    return await Send({
        method,
        url,
        data: {
            name: name,
        },
    });
};

export default GigTypesEditPage;
