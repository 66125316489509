import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import router from 'react-router-dom/es/Router';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useFormik } from 'formik';
import moment from 'moment';
import { v4 } from 'uuid';
import * as yup from 'yup';

import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import { AppContext } from '../../../Contexts/AppContext';
import { EmployerContext } from '../../../Contexts/EmployerContext';
import { Send } from '../../../Misc/backend';
import { IS_DESKTOP_MEDIA_QUERY } from '../../../Misc/constants';

import './index.scss';

const TaxReportPage = () => {
    const [t] = useTranslation();
    const { appState, appDispatch } = useContext(AppContext);
    const { employerState } = useContext(EmployerContext);
    const [loading, setLoading] = useState(false);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [confirmSendingVisible, setConfirmSendingVisible] = useState(false);

    const validationSchema = yup.object({
        restaurantId: yup.string().required(t('restaurant_is_required')),
        datePeriod: yup.date().typeError('Must be a valid date period').required(t('date_is_required')),
    });

    const formik = useFormik({
        initialValues: {
            datePeriod: moment(),
            restaurantId: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const [year, month] = values.datePeriod.format('YYYY-MM').split('-');
                const result = await getTaxReport(values.restaurantId, year, month);
                if (result.byteLength > 0) {
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Report_${year}-${month}.xml`);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    appDispatch({
                        type: 'addSnack',
                        payload: {
                            id: v4(),
                            variant: 'error',
                            autoHideDuration: 30000,
                            message: 'Sorry, it seems report is empty. Please change filter parameters and try again.',
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },
    });

    useEffect(() => {
        if (employerState?.restaurants?.length === 1) {
            formik.setFieldValue('restaurantId', employerState.restaurants[0].id);
        }
    }, [employerState.restaurants]);

    const handleTaxReportSend = async () => {
        try {
            if (formik.values.datePeriod) {
                const [year, month] = formik.values.datePeriod.format('YYYY-MM').split('-');
                await sendTaxReport(formik.values.restaurantId, year, month);
                appDispatch({
                    type: 'addSnack',
                    payload: {
                        variant: 'success',
                        message: 'Report has been sent successfully',
                    },
                });
                setConfirmSendingVisible(false);
            }
        } catch (e) {
            console.log(e);
            if (e.status === 500 || 403 || 404) {
                appDispatch({
                    type: 'addSnack',
                    payload: {
                        id: v4(),
                        variant: 'error',
                        autoHideDuration: 30000,
                        message: 'Ooops, something wrong.',
                    },
                });
            }
        }
    };

    const handleAuthRedirect = async () => {
        try {
            if (!appState?.user?.isSkatteverketConfigured) {
                window.open(appState.user.urlToSkatteverketConfiguration, '_blank');
                //window.location.replace(response.urlToRedirect);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <OverlayLoader loading={loading}>
            <Helmet>
                <title>GPG App - Tax Report</title>
            </Helmet>
            <div className="page">
                <Box mb={2} className="page-header">
                    {t('tax_report')}
                </Box>
                <div className="report">
                    <form onSubmit={formik.handleSubmit} className="card report-wrapper">
                        <Box mb={2} className="fs-24 bold primary_black">
                            {t('generate_report')}
                        </Box>
                        <Box my={2}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="restaurantId-label" color="secondary">
                                    {t('choose_restaurant')}
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="restaurantId-label"
                                    id="restaurantId"
                                    name="restaurantId"
                                    color="secondary"
                                    value={formik.values.restaurantId}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.restaurantId)}
                                    helperText={formik.errors.restaurantId}
                                    label={t('choose_restaurant')}
                                >
                                    <MenuItem value="">
                                        <em className="additional_grey_2">{t('choose_restaurant')}</em>
                                    </MenuItem>
                                    {employerState?.restaurants?.map((item) => {
                                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                    })}
                                </Select>
                                {formik.errors.restaurantId && <FormHelperText error>{formik.errors.restaurantId}</FormHelperText>}
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: isDesktop ? 'row' : 'column' }}>
                            <Box my={2} sx={{ maxWidth: isDesktop ? 220 : 'unset' }}>
                                <FormControl variant="outlined" fullWidth>
                                    <KeyboardDatePicker
                                        autoOk
                                        id="datePeriod"
                                        format="YYYY-MM"
                                        inputVariant="outlined"
                                        label={t('select_period')}
                                        color="secondary"
                                        views={['month', 'year']}
                                        value={formik.values.datePeriod}
                                        onChange={(value) => formik.setFieldValue('datePeriod', value)}
                                        error={formik.touched.datePeriod && Boolean(formik.errors.datePeriod)}
                                        helperText={formik.touched.datePeriod && formik.errors.datePeriod}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box my={2} className="right-column">
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                size="large"
                                className="align-right"
                                style={{ maxWidth: 220 }}
                            >
                                {t('download_report')}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                className="align-right"
                                style={{ maxWidth: 220 }}
                                onClick={
                                    appState?.user?.isSkatteverketConfigured ? () => setConfirmSendingVisible(true) : handleAuthRedirect
                                }
                            >
                                {appState?.user?.isSkatteverketConfigured ? t('Send') : t('authorization')}
                            </Button>
                        </Box>
                        {!appState?.user?.isSkatteverketConfigured && <em className="additional_grey_2">{t('please_auth')}</em>}
                    </form>
                </div>
            </div>
            <Dialog
                open={confirmSendingVisible}
                onClose={() => setConfirmSendingVisible(false)}
                aria-labelledby="form-dialog-title"
                className="confirmation"
                fullScreen={!isDesktop}
            >
                <DialogContent>
                    <Box my={3} className="primary_black fs-24 poppins">
                        {t('confirm_sending_report')}
                    </Box>
                    {isDesktop && (
                        <Box my={2} className="actions">
                            <Button
                                fullWidth
                                size="large"
                                color="secondary"
                                variant="outlined"
                                type="submit"
                                onClick={() => setConfirmSendingVisible(false)}
                                className="w-20"
                            >
                                {t('no')}
                            </Button>
                            <Button
                                fullWidth
                                size="large"
                                color="primary"
                                variant="contained"
                                type="submit"
                                onClick={handleTaxReportSend}
                                className="w-40"
                            >
                                {t('yes')}
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </OverlayLoader>
    );
};

const getTaxReport = async (restaurantId, year, month) => {
    let method = 'get';
    let url = `/skatteverket/report/${restaurantId}/${year}/${month}/download`;

    return await Send({
        method,
        url,
        responseType: 'arraybuffer',
    });
};

const sendTaxReport = async (restaurantId, year, month) => {
    let method = 'post';
    let url = `/skatteverket/report/${restaurantId}/${year}/${month}/send`;

    return await Send({
        method,
        url,
        data: {},
    });
};

export default TaxReportPage;
