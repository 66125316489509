import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Rating from '@material-ui/lab/Rating';
import classNames from 'classnames';

import { AppContext } from '../../Contexts/AppContext';
import { Send } from '../../Misc/backend';
import { IN_PROGRESS, NOT_YET_STARTED } from '../../Misc/constants';
import { IS_DESKTOP_MEDIA_QUERY } from '../../Misc/constants';
import { getGigStatus } from '../../Misc/gig';
import EmployeeInfoBar from '../EmployeeInfoBar/EmployeeInfoBar';
import GigInfoBar from '../GigStatusBar/GigStatusBar';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import Review from '../Review/Review';
import ReviewForm from '../Review/ReviewForm';

import './index.scss';

const StyledRating = withStyles({
    iconFilled: {},
    iconHover: {},
})(Rating);

const Contract = ({ contract, gig, staticHost, setGig }) => {
    const { appState } = useContext(AppContext);
    const [value, setValue] = useState(1);
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [expandedReviews, setExpandedReviews] = useState(false);
    const [showGigIsInProgressWarning, setShowGigIsInProgressWarning] = useState(false);
    const [showGigCancelingConfirmation, setGigCancelingConfirmation] = useState(false);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [t] = useTranslation();
    const [gigStatus, setGigStatus] = useState(null);

    useEffect(() => {
        async function loadStatus(contract) {
            try {
                setLoading(true);
                setReviews(await getMoonlighterReviews(contract.moonlighter.id));
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }

        if (contract.id && typeof contract?.moonlighter?.id !== 'undefined') {
            loadStatus(contract);
        }

        if (contract.finishedAt && !contract?.moonlighterFeedbackExists && !contract.moonlighterFeedback) {
            setValue(0);
        }

        let updateStatusInterval = setInterval(() => {
            if (gig) {
                setGigStatus(getGigStatus(gig));
            }
        }, 30000);

        setGigStatus(getGigStatus(gig));

        return () => {
            clearInterval(updateStatusInterval);
        };
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCancel = async () => {
        setLoading(true);
        try {
            await cancelContract(contract.id);
            //cancel is ok change contract state in gig property
            updateContractInGig({ cancelledAt: true });
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setGigCancelingConfirmation(false);
        }
    };

    const updateContractInGig = (update) => {
        const newGig = { ...gig };
        newGig.contracts = newGig.contracts.map((item) => {
            if (item.id !== contract.id) {
                return item;
            } else {
                for (var key in update) {
                    if (update.hasOwnProperty(key)) {
                        contract[key] = update[key];
                    }
                }

                return contract;
            }
        });
        setGig(newGig);
    };

    const handleFinish = async (contract) => {
        setLoading(true);
        try {
            const result = await finishContract(contract.id);
            //finish is ok change contract state in gig property
            updateContractInGig({ finishedAt: true });
            setValue(0);

            if (result.error_code !== null) {
            } else {
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setShowGigIsInProgressWarning(false);
        }
    };

    const handleApprovePayout = () => {
        if (gigStatus?.code === IN_PROGRESS || gigStatus?.code === NOT_YET_STARTED) {
            setShowGigIsInProgressWarning(true);
        } else {
            handleFinish(contract);
        }
    };

    const handleCancelingConfirmation = () => {
        setGigCancelingConfirmation(true);
    };

    const handleReviewSubmitSuccess = (newReview) => {
        setReviews((prev) => [...prev, newReview]);
        setValue(1);
    };

    return (
        <OverlayLoader loading={loading}>
            <Box mb={3} className="contract">
                <div className="tile-content">
                    <div className="tile-content-header fs-16 primary_black poppins">
                        <div className="left-column">
                            <Box mr={1.5} className="image-circle small">
                                {contract?.moonlighter?.userPicHash ? (
                                    <img src={staticHost + '/' + contract?.moonlighter?.userPicHash + '.jpg'} />
                                ) : (
                                    <AccountCircleOutlinedIcon />
                                )}
                            </Box>
                            <div className="status">
                                <div className="primary_black fs-16 bold poppins truncate">
                                    {contract.moonlighter?.fullName}, {contract.moonlighter?.age} y.o. &nbsp;
                                    {contract.cancelledAt && <span className="badge error">cancelled</span>}
                                </div>
                                <div className="align-top">
                                    <StyledRating
                                        readOnly
                                        value={contract.moonlighter?.rating}
                                        name="simple-controlled"
                                        className="fs-16 align-top"
                                    />
                                    <span className="fs-12 additional_grey_2 align-top">
                                        {contract.moonlighter?.rating} / {reviews.length} {t('review(s)')}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/*desktop header buttons*/}

                        {isDesktop && (
                            <>
                                {!contract.finishedAt && !contract.cancelledAt && (
                                    <div className="flex-align-horizontal-middle">
                                        <GigInfoBar gig={gig} contract={contract} />
                                        {gigStatus?.code !== NOT_YET_STARTED && (
                                            <Box>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    color="primary"
                                                    variant="contained"
                                                    type="submit"
                                                    onClick={handleApprovePayout}
                                                >
                                                    <CheckCircleOutlineIcon />
                                                    &nbsp;
                                                    {t('approve_payout')}
                                                </Button>
                                            </Box>
                                        )}
                                        {gigStatus?.code === NOT_YET_STARTED && (
                                            <Box ml={2}>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    color="secondary"
                                                    variant="outlined"
                                                    type="submit"
                                                    onClick={handleCancelingConfirmation}
                                                >
                                                    <CancelOutlinedIcon />
                                                    &nbsp;{t('cancel')}
                                                </Button>
                                            </Box>
                                        )}
                                    </div>
                                )}

                                {contract.finishedAt && !loading && (
                                    <div className="flex-align-horizontal-middle">
                                        <GigInfoBar gig={gig} contract={contract} />
                                    </div>
                                )}

                                {contract.cancelledAt && !loading && (
                                    <div className="flex-align-horizontal-middle">
                                        <EmployeeInfoBar contract={contract} />
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <Tabs value={value} onChange={handleChange} variant="scrollable" indicatorColor="primary" style={{ maxWidth: '100%' }}>
                        {contract.finishedAt && <Tab value={0} className="poppins additional_grey_1 bold fs-16" label={t('review')} />}
                        <Tab value={1} className="poppins additional_grey_1 bold fs-16" label={t('recent_jobs')} />
                        <Tab value={2} className="poppins additional_grey_1 bold fs-16" label={t('description')} />
                    </Tabs>

                    <div className={classNames('tab-body additional_grey_2 fs-14', { 'display-none': value !== 0 })}>
                        <ReviewForm contractId={contract.id} onSubmitSuccess={handleReviewSubmitSuccess} />
                    </div>

                    <div className={classNames('tab-body additional_grey_2 fs-14', { 'display-none': value !== 1 })}>
                        {reviews?.map((item, index) => {
                            return index < 3 ? <Review review={item} staticHost={appState.staticHost} /> : null;
                        })}
                        {expandedReviews &&
                            reviews?.map((item, index) => {
                                return index >= 3 ? <Review review={item} staticHost={appState.staticHost} /> : null;
                            })}
                        {!expandedReviews && reviews.length > 3 && (
                            <div
                                onClick={() => setExpandedReviews(true)}
                                className="primary_black fs-14 bold uppercase pointer align-center"
                            >
                                {t('show')} {reviews.length - 3} {t('more')}
                            </div>
                        )}
                        {expandedReviews && (
                            <div
                                onClick={() => setExpandedReviews(false)}
                                className="primary_black fs-14 bold uppercase pointer align-center"
                            >
                                {t('show')} {t('less')}
                            </div>
                        )}
                        {reviews?.length === 0 && <div my={2}>{t('no_reviews_here_yet')}.</div>}
                    </div>

                    <div className={classNames('tab-body additional_grey_2 fs-14', { 'display-none': value !== 2 })}>
                        {contract.moonlighter?.description === null && <Box my={2}>{t('no_description_was_provided')}.</Box>}
                        {contract.moonlighter?.description}
                    </div>
                </div>

                {/*mobile bottom buttons*/}
                {!isDesktop && (
                    <div className="mobile-buttons">
                        {!contract.finishedAt && !contract.cancelledAt && (
                            <>
                                {gigStatus?.code && gigStatus?.code !== NOT_YET_STARTED && (
                                    <Box mb={1}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            onClick={handleApprovePayout}
                                        >
                                            <CheckCircleOutlineIcon />
                                            &nbsp;{t('approve_payout')}
                                        </Button>
                                    </Box>
                                )}
                                {gigStatus?.code === NOT_YET_STARTED && (
                                    <Box>
                                        <Button
                                            fullWidth
                                            size="large"
                                            color="secondary"
                                            variant="outlined"
                                            type="submit"
                                            onClick={handleCancelingConfirmation}
                                        >
                                            <CancelOutlinedIcon />
                                            &nbsp;{t('cancel')}
                                        </Button>
                                    </Box>
                                )}
                            </>
                        )}
                    </div>
                )}
            </Box>
            <Dialog
                open={showGigIsInProgressWarning}
                onClose={() => setShowGigIsInProgressWarning(false)}
                aria-labelledby="form-dialog-title"
                className="confirmation"
                fullScreen={!isDesktop}
            >
                <DialogContent>
                    <OverlayLoader loading={loading}>
                        <Box my={3} className="primary_black fs-24 poppins align-center">
                            {t('gig_is_in_progress_do_you')}
                        </Box>
                        <Box my={2} className="actions">
                            <Button
                                fullWidth
                                size="large"
                                color="secondary"
                                variant="outlined"
                                type="submit"
                                onClick={() => setShowGigIsInProgressWarning(false)}
                            >
                                <CancelOutlinedIcon />
                                &nbsp;{t('cancel')}
                            </Button>
                            <Box ml={2}>
                                <Button
                                    fullWidth
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={() => handleFinish(contract)}
                                >
                                    <CheckCircleOutlineIcon />
                                    &nbsp;{t('approve_payout')}
                                </Button>
                            </Box>
                        </Box>
                    </OverlayLoader>
                </DialogContent>
            </Dialog>
            <Dialog
                open={showGigCancelingConfirmation}
                onClose={() => setGigCancelingConfirmation(false)}
                aria-labelledby="form-dialog-title"
                className="confirmation"
                fullScreen={!isDesktop}
            >
                <DialogContent>
                    <OverlayLoader loading={loading}>
                        <Box my={3} className="primary_black fs-24 poppins align-center">
                            {t('are_you_sure_you_want_to_cancel_this_hired')}
                        </Box>
                        <Box my={2} className="actions">
                            <Button
                                fullWidth
                                size="large"
                                color="secondary"
                                variant="outlined"
                                type="submit"
                                onClick={() => setGigCancelingConfirmation(false)}
                            >
                                <CancelOutlinedIcon />
                                &nbsp;{t('no')}
                            </Button>
                            <Box ml={2}>
                                <Button fullWidth size="large" color="primary" variant="contained" type="submit" onClick={handleCancel}>
                                    <CheckCircleOutlineIcon />
                                    &nbsp;{t('yes')}
                                </Button>
                            </Box>
                        </Box>
                    </OverlayLoader>
                </DialogContent>
            </Dialog>
        </OverlayLoader>
    );
};

const getMoonlighterReviews = async (id) => {
    let method = 'get';
    let url = `/moonlighters/${id}/feedbacks?page=0&size=100000`;

    return await Send({
        method,
        url,
    });
};

const finishContract = async (contractId) => {
    let method = 'post';
    let url = '/employers/me/completion-contract-intents';

    return await Send({
        method,
        url,
        data: `\"${contractId}\"`,
    });
};

const cancelContract = async (contractId) => {
    let method = 'post';
    let url = '/employers/me/cancellation-contract-intents';

    return await Send({
        method,
        url,
        data: `\"${contractId}\"`,
    });
};

const getPayment = async (contractId) => {
    let method = 'get';
    let url = `/employers/me/contracts/${contractId}/payment-details`;

    return await Send({
        method,
        url,
    });
};

export default Contract;
