import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';

import 'moment/locale/sv';
import 'moment/locale/en-gb';

import AppRoute from './Components/AppRouter/AppRoute';
import AppRouter from './Components/AppRouter/AppRouter';
import Authorization from './Components/Authorization/Authorization';
import ContextLoader from './Components/ContextLoader/ContextLoader';
import Privacy from './Components/Privacy/Privacy';
import Terms from './Components/Terms/Terms';
import { AppContext, appInitState } from './Contexts/AppContext';
import { EmployeeContext, employeeInitState } from './Contexts/EmployeeContext';
import { EmployerContext, employerInitState } from './Contexts/EmployerContext';
import MainLayout from './Layouts/MainLayout/MainLayout';
import UnauthorizedLayout from './Layouts/UnauthorizedLayout/UnauthorizedLayout';
import { configureAxios } from './Misc/backend';
import customHistory from './Misc/history';
import user from './Misc/user';
import appReducer from './Reducers/AppReducer';
import employeeReducer from './Reducers/EmployeeReducer';
import employerReducer from './Reducers/EmployerReducer';
import i18n from './i18n';
import Translations from './translations.js';

import './static/styles/app.scss';

const theme = createMuiTheme({
    spacing: 8,
    palette: {
        common: {
            black: '#000',
            white: '#fff',
        },
        background: {
            paper: '#fff',
            default: '#fafafa',
        },
        primary: {
            light: 'rgba(182, 255, 251, 1)',
            main: '#000000',
            dark: 'rgba(0, 0, 0, 1)',
            contrastText: 'rgba(255, 255, 255, 1)',
        },
        secondary: {
            light: 'rgba(196, 196, 196, 1)',
            main: 'rgba(53, 58, 70, 1)',
            dark: 'rgba(34, 39, 44, 1)',
            contrastText: '#fff',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        text: {
            primary: 'rgba(10, 25, 45, 1)',
            secondary: 'rgba(108, 118, 132, 1)',
            disabled: 'rgba(149, 157, 169, 1)',
            hint: 'rgba(108, 118, 132, 1)',
        },
    },
});

const useBeforeFirstRender = (f) => {
    const [hasRendered, setHasRendered] = useState(false);
    useEffect(() => setHasRendered(true), [hasRendered]);
    if (!hasRendered) {
        f();
    }
};

const googleApiKey =
    typeof process.env.REACT_APP_GOOGLE_API_KEY === 'string' && process.env.REACT_APP_GOOGLE_API_KEY.length
        ? process.env.REACT_APP_GOOGLE_API_KEY
        : 'AIzaSyBisjgk0nFsPDHZhnBIxMz51IRgeUoA_J8';

const commonClasses = {
    opacity: 0.9,
    fontFamily: 'Roboto',
    borderRadius: '6px',
    fontSize: '12px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    fontFeatureSettings: "'liga' off",
};

const useStyles = makeStyles(() => ({
    variantSuccess: {
        backgroundColor: '#212121 !important',
        color: 'rgba(62, 243, 233, 1) !important',
        ...commonClasses,
    },
    variantError: {
        backgroundColor: '#212121 !important',
        color: '#f44336 !important',
        ...commonClasses,
    },
    variantWarning: {
        backgroundColor: '#212121 !important',
        color: '#FFE476 !important',
        ...commonClasses,
    },
    variantInfo: {
        backgroundColor: '#212121 !important',
        ...commonClasses,
    },
}));

var locale = localStorage.getItem('i18nextLng') || window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);
i18n.init({
    lng: locale.slice(0, 2),
    fallbackLng: 'en',
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },
    react: {
        wait: true,
    },
    resources: Translations,
});

const App = () => {
    const [t, existingI18n] = useTranslation();
    const [appState, appDispatch] = useReducer(appReducer, appInitState);
    const [employerState, employerDispatch] = useReducer(employerReducer, employerInitState);
    const [employeeState, employeeDispatch] = useReducer(employeeReducer, employeeInitState);
    const [updatedAt, setUpdatedAt] = useState(moment().toISOString());
    const [contextLoaded, setContextLoaded] = useState(false);
    let history = useHistory();

    useBeforeFirstRender(() => {
        configureAxios(customHistory, appDispatch);
    });

    const handleAuthorized = () => {
        setUpdatedAt(moment().toISOString());
    };

    const handleContextLoaded = (forceRedirectUrl = null) => {
        if (forceRedirectUrl) {
            history.push(forceRedirectUrl);
        }
        setContextLoaded(true);
    };

    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={existingI18n.language}>
            <AppContext.Provider value={{ appState, appDispatch }}>
                <EmployerContext.Provider value={{ employerState, employerDispatch }}>
                    <EmployeeContext.Provider value={{ employeeState, employeeDispatch }}>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} classes={classes}>
                                {!user.IsAuthorized() ? (
                                    <Switch>
                                        <Route path={'/'} exact render={() => <Authorization update={handleAuthorized} />} />
                                        <Route
                                            path={'/auth/employer/email-magic-link'}
                                            exact
                                            render={() => <Authorization update={handleAuthorized} />}
                                        />
                                        <AppRoute path={'/privacy'} exact layout={UnauthorizedLayout} component={Privacy} />
                                        <AppRoute path={'/terms'} exact layout={UnauthorizedLayout} component={Terms} />
                                    </Switch>
                                ) : !contextLoaded ? (
                                    <ContextLoader update={handleContextLoaded} language={existingI18n.language} />
                                ) : (
                                    <Fragment>
                                        <AppRouter />
                                    </Fragment>
                                )}
                            </SnackbarProvider>
                        </ThemeProvider>
                    </EmployeeContext.Provider>
                </EmployerContext.Provider>
            </AppContext.Provider>
        </MuiPickersUtilsProvider>
    );
};

export default App;
