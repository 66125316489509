import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { BlockOutlined, CompareArrows, SearchOutlined } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment/moment';

import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import { AppContext } from '../../../Contexts/AppContext';
import { EmployerContext } from '../../../Contexts/EmployerContext';
import { getEmployeeBindings, getMoonlighters, Send } from '../../../Misc/backend';
import { PER_PAGE } from '../../../Misc/constants';
import { Images } from '../../../static/images';

import './MoonlightersPage.scss';

const MoonlightersPage = () => {
    const [loading, setLoading] = useState(false);
    const [moonlightersList, setMoonlightersList] = useState([]);
    const [t] = useTranslation();
    const { appState } = useContext(AppContext);
    const isAdmin = appState?.user.roles.indexOf('Admin') !== -1;
    const { employerState } = useContext(EmployerContext);
    const [internalIdRestaurantNamesMap, setInternalIdRestaurantNamesMap] = useState({});
    const [searchItem, setSearchItem] = useState('');
    const [filteredMoonlightersList, setFilteredMoonlightersList] = useState([]);
    const [ratingFilter, setRatingFilter] = useState(0);
    const [skillsFilter, setSkillsFilter] = useState('_none');
    const [currentPage, setCurrentPage] = useState(1);
    const restIds = useMemo(() => employerState.restaurants.map((item) => item.id), [employerState.restaurants]);
    const restNames = useMemo(
        () =>
            employerState.restaurants.reduce((accumulator, currentValue) => {
                accumulator[currentValue.id] = currentValue.name;
                return accumulator;
            }, {}),
        [employerState.restaurants]
    );

    useEffect(() => {
        async function loadMoonlighters() {
            setLoading(true);
            try {
                const moonlighters = await getMoonlighters();
                setMoonlightersList(moonlighters);
                setFilteredMoonlightersList(moonlighters);
                const restaurantsBindings = {};
                for await (const restId of restIds) {
                    restaurantsBindings[restId] = (await getEmployeeBindings(restId)).map((item) => item.internalId);
                }
                const map = {};
                moonlighters.forEach((moonlighter) => {
                    const restaurantsOfCurrentMoonlighter = [];
                    for (let restId in restaurantsBindings) {
                        if (restaurantsBindings[restId].includes(moonlighter.id)) {
                            restaurantsOfCurrentMoonlighter.push(restId);
                        }
                    }
                    map[moonlighter.id] = restaurantsOfCurrentMoonlighter.map((item) => restNames[item]);
                });
                setInternalIdRestaurantNamesMap(map);
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }

        loadMoonlighters();
    }, []);

    const handleBlockMoonlighter = async (id) => {
        if (confirm('Do you really want to block this Moonlighter?') == true) {
            try {
                await blockMoonlighter(id);
            } catch (e) {
                console.log(e);
            }
        }
    };

    useEffect(() => {
        const filteredItems = moonlightersList.filter(
            (item) =>
                (item.fullName.toLowerCase().includes(searchItem.toLowerCase()) ||
                    item.email.toLowerCase().includes(searchItem.toLowerCase())) &&
                item.rating >= ratingFilter &&
                (skillsFilter === '_none' || item.skillTagIds.includes(skillsFilter))
        );
        setFilteredMoonlightersList(filteredItems);
    }, [ratingFilter, searchItem, moonlightersList, skillsFilter]);

    return (
        <OverlayLoader loading={loading}>
            <Helmet>
                <title>GPG App - Moonlighters list</title>
            </Helmet>
            <div className="page">
                <div className="moonlighters-header">
                    <div className="search-input">
                        <TextField
                            id="moonlighters-search-field"
                            name="moonlighters-search-field"
                            variant="outlined"
                            color="secondary"
                            placeholder="Search gigslers..."
                            value={searchItem}
                            onChange={(e) => setSearchItem(e.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className="filters">
                        <span className="fs-14 additional_grey_2">{t('show_by')}:</span>
                        <div className="flex-align-horizontal-middle select">
                            <span className="fs-14 additional_grey_2">{t('rating')}: </span>
                            <FormControl>
                                <Select
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    id="layout-select"
                                    value={ratingFilter}
                                    onChange={(e) => setRatingFilter(e.target.value)}
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    <MenuItem value={5}>
                                        <Rating value={5} readOnly size="small"></Rating>
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        <Rating value={4} readOnly size="small"></Rating>
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        <Rating value={3} readOnly size="small"></Rating>
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        <Rating value={2} readOnly size="small"></Rating>
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        <Rating value={1} readOnly size="small"></Rating>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex-align-horizontal-middle select">
                            <span className="fs-14 additional_grey_2">{t('skills')}: </span>
                            <FormControl>
                                <Select
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    id="layout-select"
                                    value={skillsFilter}
                                    defaultValue="_none"
                                    onChange={(e) => setSkillsFilter(e.target.value)}
                                >
                                    <MenuItem value="_none">None</MenuItem>
                                    {appState.skills.map(({ id, name }) => {
                                        return <MenuItem value={id}>{name}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                {filteredMoonlightersList.length === 0 ? (
                    <div className="additional_grey_2 lh-46 fs-16">{t('no_gigslers_found')}</div>
                ) : (
                    <>
                        {filteredMoonlightersList?.map(
                            ({ fullName, email, lastSignedIn, rating, id, isActive, userPicHash, isSignedInExternalSystem }, index) => {
                                return index >= (currentPage - 1) * PER_PAGE && index < currentPage * PER_PAGE ? (
                                    <div key={id} className="moonlighter-accordion-item">
                                        <div className="space-between">
                                            <div className="moonlighter-info-wrapper center">
                                                <div className="moonlighter-info-column">
                                                    <div className="flex-row space-between">
                                                        <div className="additional_grey_1 bold fs-16">{t('name')}</div>
                                                    </div>
                                                    <div className="additional_grey_2 lh-46 fs-16">{fullName}</div>
                                                </div>
                                                <div className="moonlighter-info-column">
                                                    <div className="flex-row space-between">
                                                        <div className="additional_grey_1 bold fs-16">{t('email')}</div>
                                                    </div>
                                                    <div className="additional_grey_2 lh-46 fs-16">{email}</div>
                                                </div>
                                                <div className="moonlighter-info-column rating">
                                                    <div className="flex-row">
                                                        <div className="additional_grey_1 bold fs-16">{t('rating')}&nbsp;&nbsp;</div>
                                                        <span className="additional_grey_2 fs-12 counter">{rating}&nbsp;/&nbsp;5</span>
                                                    </div>
                                                    <div className="rating additional_grey_2 lh-46 fs-16">
                                                        <Rating value={rating} readOnly></Rating>
                                                    </div>
                                                </div>
                                                <div className="moonlighter-info-column">
                                                    <div className="flex-row space-between">
                                                        <div className="additional_grey_1 bold fs-16">{t('last_signed_in')}</div>
                                                    </div>
                                                    <div className="additional_grey_2 lh-46 fs-16">
                                                        {moment(lastSignedIn).format('YYYY-MM-DD, h:mm')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="space-between center">
                                                {!isActive && <span className="badge error">{t('blocked')}</span>}
                                                {isActive && (
                                                    <Button
                                                        variant="text"
                                                        color="secondary"
                                                        size="small"
                                                        title="Block Moonlighter"
                                                        onClick={() => handleBlockMoonlighter(id)}
                                                    >
                                                        <BlockOutlined color="error" />
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="binded">
                                            {internalIdRestaurantNamesMap[id]?.length > 0 && (
                                                <div className="additional_grey_2 fs-16">
                                                    <span className="bold">{internalIdRestaurantNamesMap[id]?.join(', ')}</span>
                                                </div>
                                            )}
                                            {isSignedInExternalSystem && internalIdRestaurantNamesMap[id]?.length > 0 && (
                                                <CompareArrows className="additional_grey_2" />
                                            )}
                                            {isSignedInExternalSystem && (
                                                <span className="additional_grey_2 bold fs-16">Personalkollen</span>
                                            )}
                                        </div>
                                    </div>
                                ) : null;
                            }
                        )}
                    </>
                )}
                <Pagination
                    count={Math.floor(filteredMoonlightersList.length / PER_PAGE) + 1}
                    page={currentPage}
                    shape="rounded"
                    onChange={(event, page) => setCurrentPage(page)}
                />
            </div>
        </OverlayLoader>
    );
};

const blockMoonlighter = async (id) => {
    let method = 'post';
    let url = `/employers/me/moonlighters/${id}/blocks`;

    return await Send({
        method,
        url,
        data: {},
    });
};

const getBlockedMoonlighters = async (id) => {
    let method = 'get';
    let url = `/employers/me/moonlighter-blocks/${id}`;

    return await Send({
        method,
        url,
    });
};
export default MoonlightersPage;
