import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';

import { AppContext } from '../../Contexts/AppContext';
import { createPayment, Send } from '../../Misc/backend';
import { roundAccurately } from '../../Misc/calculations';
import { IS_DESKTOP_MEDIA_QUERY } from '../../Misc/constants';
import AccordionRow from '../AccordionRow/AccrordionRow';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import Review from '../Review/Review';

import './index.scss';

const StyledRating = withStyles({
    iconFilled: {
        // color: '#FFE476',
    },
    iconHover: {
        // color: '#FFE476',
    },
})(Rating);

const ApplicationMobile = ({ application, gig, setGig, staticHost, newApplicants }) => {
    const { appState } = useContext(AppContext);
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [taxes, setTaxes] = useState(null);
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [expandedReviews, setExpandedReviews] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);

    useEffect(() => {
        async function getReviews(id) {
            setLoading(true);
            try {
                setReviews(await getMoonlighterReviews(id));
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }

        if (typeof application?.moonlighter?.id !== 'undefined') {
            getReviews(application.moonlighter.id);
        }
        if (!application?.moonlighterFeedbackExists) {
            setValue(0);
        }
    }, [application]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleApprove = async (gigId, application) => {
        setLoading(true);
        try {
            const contract = await approveApplication(gigId, application.moonlighter.id);
            if (contract) {
                try {
                    await createPayment(contract.id);
                } catch (e) {
                    //TODO: handle create payment errors
                }

                const newGig = { ...gig };
                newGig.applications = newGig.applications.filter((item) => item.moonlighter.id !== application.moonlighter.id);
                newGig.contracts.push(contract);
                setGig(newGig);
            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    const handleShowTaxesModal = async () => {
        setDetailsVisible(true);
        setLoading(true);
        try {
            setTaxes(await getPrecisionTaxes(gig.id, application.moonlighter.id));
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    const handleDownloadCV = async () => {
        try {
            const response = await getCV(application.moonlighter.id);
            let blob = new Blob([response], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Box mb={3} className="application">
            <div className="tile-content">
                <div className="tile-content-header fs-16 primary_black poppins">
                    <div className="left-column">
                        <Box mr={1.5} className="image-circle small">
                            {application?.moonlighter?.userPicHash ? (
                                <img src={staticHost + '/' + application?.moonlighter?.userPicHash + '.jpg'} />
                            ) : (
                                <AccountCircleOutlinedIcon />
                            )}
                        </Box>
                        <div className="status">
                            <div className="primary_black fs-16 bold poppins truncate">
                                {application.moonlighter?.fullName},&nbsp;
                                {application.moonlighter?.age} y.o.
                                {newApplicants.indexOf(application.moonlighter.id) !== -1 && <span className="badge new">{t('new')}!</span>}
                            </div>
                            <div className="align-top">
                                <StyledRating
                                    readOnly
                                    value={application.moonlighter?.rating}
                                    name="simple-controlled"
                                    className="fs-16 align-top"
                                />
                                <span className="fs-12 additional_grey_2 align-top">
                                    {' '}
                                    {application.moonlighter?.rating} / {reviews.length} {t('review(s)')}
                                </span>
                            </div>
                        </div>
                    </div>

                    {isDesktop && (
                        <div className="right-column">
                            <Button fullWidth size="large" color="primary" variant="contained" type="submit" onClick={handleShowTaxesModal}>
                                <CheckCircleOutlineIcon />
                                &nbsp;{t('hire')}
                            </Button>
                        </div>
                    )}
                </div>

                <div>
                    <AccordionRow
                        onOpen={() => setValue(0)}
                        title={<span className="fs-16 bold poppins additional_grey_1">{t('recent_jobs')}</span>}
                        open={value === 0}
                    >
                        <div className="tab-body additional_grey_2 fs-14">
                            <OverlayLoader loading={loading}>
                                {reviews?.map((item, index) => {
                                    return index < 3 ? <Review review={item} staticHost={appState.staticHost} /> : null;
                                })}
                                {expandedReviews &&
                                    reviews?.map((item, index) => {
                                        return index >= 3 ? <Review review={item} staticHost={appState.staticHost} /> : null;
                                    })}
                                {!expandedReviews && reviews.length > 3 && (
                                    <div
                                        onClick={() => setExpandedReviews(true)}
                                        className="primary_black fs-14 bold uppercase pointer align-center"
                                    >
                                        Show {reviews.length - 3} more
                                    </div>
                                )}
                                {expandedReviews && (
                                    <div
                                        onClick={() => setExpandedReviews(false)}
                                        className="primary_black fs-14 bold uppercase pointer align-center"
                                    >
                                        Show less
                                    </div>
                                )}
                                {reviews?.length === 0 && <Box my={2}>{t('no_reviews_here_yet')}.</Box>}
                            </OverlayLoader>
                        </div>
                    </AccordionRow>

                    <AccordionRow
                        onOpen={() => setValue(1)}
                        title={<span className="fs-16 bold poppins additional_grey_1">{t('description')}</span>}
                        open={value === 1}
                    >
                        <div className="tab-body additional_grey_2 fs-14">
                            {application.moonlighter?.description === null && <span>{t('no_description_was_provided')}.</span>}
                            {application.moonlighter?.description}
                        </div>
                    </AccordionRow>
                </div>

                <div className="flex-align-horizontal-middle space-between pointer">
                    <div className="fs-12 poppins additional_grey_2">
                        <Box mx={1} className="flex-align-horizontal-middle">
                            <SmartphoneIcon className="additional_grey_3" />
                            &nbsp;
                            <a className="additional_grey_1" href={`tel:${application.moonlighter?.phone}`}>
                                {application.moonlighter?.phone}
                            </a>
                        </Box>
                        <Box mx={1} className="flex-align-horizontal-middle">
                            <MailOutlineIcon className="additional_grey_3" />
                            &nbsp;
                            <a className="additional_grey_1" href={`mailto:${application.moonlighter?.email}`}>
                                {application.moonlighter?.email}
                            </a>
                        </Box>
                    </div>
                    {!isDesktop && (
                        <Box mt={1} className="right-column">
                            {application.moonlighter?.hasCv && (
                                <Button fullWidth size="large" color="primary" variant="contained" type="submit" onClick={handleDownloadCV}>
                                    {t('Download CV')}
                                </Button>
                            )}
                            <Button fullWidth size="large" color="primary" variant="contained" type="submit" onClick={handleShowTaxesModal}>
                                <CheckCircleOutlineIcon />
                                &nbsp;{t('hire')}
                            </Button>
                        </Box>
                    )}
                </div>
            </div>
            <Dialog
                open={detailsVisible}
                onClose={() => setDetailsVisible(false)}
                aria-labelledby="form-dialog-title"
                className="confirmation"
                fullScreen={!isDesktop}
            >
                <DialogContent>
                    <OverlayLoader loading={loading}>
                        <Box my={3} className="primary_black fs-24 poppins">
                            {t('hire_employee')}
                        </Box>
                        <div className="card">
                            <div className="tile-content-header fs-16 primary_black poppins">
                                <div className="flex-row">
                                    <Box mr={1.5} className="image-circle small">
                                        {application?.moonlighter?.userPicHash ? (
                                            <img src={staticHost + '/' + application?.moonlighter?.userPicHash + '.jpg'} />
                                        ) : (
                                            <AccountCircleOutlinedIcon />
                                        )}
                                    </Box>
                                    <div className="status">
                                        <div className="primary_black fs-16 bold poppins truncate">
                                            {application.moonlighter?.fullName}{' '}
                                        </div>
                                        <div className="align-top">
                                            <StyledRating
                                                readOnly
                                                value={application.moonlighter?.rating}
                                                name="simple-controlled"
                                                className="fs-16 align-top"
                                            />
                                            <span className="fs-12 additional_grey_2 align-top">
                                                {' '}
                                                {application.moonlighter?.rating} / {reviews.length} {t('review(s)')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Box className="price-row" mt={3} mb={1.5}>
                                <div className="fs-14 additional_grey_2">{t('duration')}</div>
                                <div>
                                    <span className="primary_black">{roundAccurately(moment.duration(gig.duration).asHours(), 2)}</span>
                                    &nbsp;
                                    <span className="additional_grey_2">h</span>
                                </div>
                            </Box>
                            {taxes?.amounts?.mealDeduction !== 0 && (
                                <Box className="price-row" mb={1.5}>
                                    <div className="fs-14 additional_grey_2">
                                        {t('meal_deduction')} ({taxes?.amounts?.mealDeductionThreshold})
                                    </div>
                                    <div>
                                        <span className="primary_black">{taxes?.amounts?.mealDeduction}</span>
                                        &nbsp;
                                        <span className="additional_grey_2">SEK</span>
                                    </div>
                                </Box>
                            )}
                            {taxes?.amounts?.obBaseTotal !== 0 && (
                                <Box className="price-row" mb={1.5}>
                                    <div className="fs-14 additional_grey_2">
                                        {t('ob_base_compensation')}
                                        <div className="fs-10 italic">
                                            {moment(taxes?.amounts?.obBaseIntervals[0].split('&')[0]).format('MMM Do HH:mm')} -{' '}
                                            {moment(taxes?.amounts?.obBaseIntervals[0].split('&')[1]).format('HH:mm')}
                                        </div>
                                    </div>
                                    <div>
                                        <span className="primary_black">{taxes?.amounts?.obBaseTotal}</span>
                                        &nbsp;
                                        <span className="additional_grey_2">SEK</span>
                                    </div>
                                </Box>
                            )}
                            {taxes?.amounts?.obNightTotal !== 0 && (
                                <Box className="price-row" mb={1.5}>
                                    <div className="fs-14 additional_grey_2">
                                        {t('ob_night_compensation')}
                                        <div className="fs-10 italic">
                                            {moment(taxes?.amounts?.obNightIntervals[0].split('&')[0]).format('MMM Do HH:mm')} -{' '}
                                            {moment(taxes?.amounts?.obNightIntervals[0].split('&')[1]).format('HH:mm')}
                                        </div>
                                    </div>
                                    <div>
                                        <span className="primary_black">{taxes?.amounts?.obNightTotal}</span>
                                        &nbsp;
                                        <span className="additional_grey_2">SEK</span>
                                    </div>
                                </Box>
                            )}
                            <Box className="price-row" mb={1.5}>
                                <div className="fs-14 additional_grey_2">
                                    {t('employee_tax')} ({taxes?.amounts?.employeeTaxRate}%)
                                </div>
                                <div>
                                    <span className="primary_black">{taxes?.amounts?.employeeTax}</span>
                                    &nbsp;
                                    <span className="additional_grey_2">SEK</span>
                                </div>
                            </Box>
                            <Box className="price-row" mb={1.5}>
                                <div className="fs-14 additional_grey_2">
                                    {t('employer_tax')} ({taxes?.amounts?.employerTaxRate}%)
                                </div>
                                <div>
                                    <span className="primary_black">{taxes?.amounts?.employerTax}</span>
                                    &nbsp;
                                    <span className="additional_grey_2">SEK</span>
                                </div>
                            </Box>
                            <Box className="price-row" mb={1.5}>
                                <div className="fs-14 additional_grey_2">{t('gross_salary')}</div>
                                <div>
                                    <span className="primary_black">{taxes?.amounts?.grossSalary}</span>
                                    &nbsp;
                                    <span className="additional_grey_2">SEK</span>
                                </div>
                            </Box>
                            <Box className="price-row" mb={1.5}>
                                <div className="fs-14 additional_grey_2 ">{t('net_salary')}</div>
                                <div>
                                    <span className="primary_black ">{taxes?.amounts?.netSalary}</span>
                                    &nbsp;
                                    <span className="additional_grey_2 ">SEK</span>
                                </div>
                            </Box>
                            <Box className="price-row" mb={1.5}>
                                <div className="fs-14 primary_black bold">{t('total_cost')}:</div>
                                <div>
                                    <span className="primary_black bold">{taxes?.amounts?.total}</span>
                                    &nbsp;
                                    <span className="additional_grey_2 bold">SEK</span>
                                </div>
                            </Box>
                        </div>

                        <Divider />

                        <Box mt={1} className="description align-left">
                            Pressing HIRE/APPLY you agree with the{' '}
                            <NavLink to="/terms" target="_blank" className="blacklink bold">
                                Terms of Service
                            </NavLink>
                        </Box>

                        {isDesktop && (
                            <Box my={2} className="actions">
                                <Button
                                    fullWidth
                                    size="large"
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                    onClick={() => setDetailsVisible(false)}
                                    className="w-20"
                                >
                                    {t('cancel')}
                                </Button>

                                <Button
                                    fullWidth
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={() => handleApprove(gig.id, application)}
                                    className="w-40"
                                >
                                    {t('hire_employee')}
                                </Button>
                            </Box>
                        )}
                        {!isDesktop && (
                            <Box my={2}>
                                <Box mb={2}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        onClick={() => handleApprove(gig.id, application)}
                                    >
                                        {t('hire_employee')}
                                    </Button>
                                </Box>
                                <Button
                                    fullWidth
                                    size="large"
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                    onClick={() => setDetailsVisible(false)}
                                >
                                    {t('cancel')}
                                </Button>
                            </Box>
                        )}
                    </OverlayLoader>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

const getMoonlighterReviews = async (id) => {
    let method = 'get';
    let url = `/moonlighters/${id}/feedbacks?page=0&size=100000`;

    return await Send({
        method,
        url,
    });
};

const approveApplication = async (gigId, moonlighterId) => {
    let method = 'post';
    let url = `/employers/me/gigs/${gigId}/contracts`;

    return await Send({
        method,
        url,
        data: `\"${moonlighterId}\"`,
    });
};

const getPrecisionTaxes = async (gigId, moonlighterId) => {
    let method = 'get';
    let url = `/employers/me/gigs/${gigId}/applications/${moonlighterId}`;

    return await Send({
        method,
        url,
    });
};

const getCV = async (moonlighterId) => {
    let method = 'get';
    let url = `/moonlighters/${moonlighterId}/cv`;

    return await Send({
        method,
        url,
        responseType: 'blob',
    });
};

export default ApplicationMobile;
